import React from "react";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Copyright from "./layouts/Copyright";
import { generalInfos, siteData } from "./utils/config";
// This is a React Router v5 app
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Services from "./pages/Services";
import Career from "./pages/Career";
import {
  contactInfo,
  copyright,
  footerPagelinks,
  headerData,
  socialMediaLinks,
} from "./utils/utils";

const routes: any = {
  home: Home,
  about: About,
  services: Services,
  career: Career,
  contact: Contact,
};
const Routes = () => (
  <>
    {siteData.header?.navLinks?.map((item) => {
      let Component = routes[item.url];
      if (item.show)
        return (
          <Route exact path={`/${item.url}`}>
            <Component {...(siteData as any).pages[item.url]} />
          </Route>
        );
      return <></>;
    })}
  </>
);
function App() {
  return (
    <>
      <BrowserRouter>
        <Header navLinks={headerData(generalInfos.lang)} />

        <Switch>
          <div className="main-container">
            <Route exact path="/">
              <Home containers={siteData.pages.home.containers} />
            </Route>
            <Routes />
          </div>
        </Switch>

        <Footer
          socialMediaLinks={socialMediaLinks()}
          contactinfo={contactInfo(generalInfos.lang)}
          pagelinks={footerPagelinks(generalInfos.lang)}
        />
        <Copyright
          {...copyright(generalInfos.lang)}
          companyName={generalInfos.companyName}
        />
      </BrowserRouter>
    </>
  );
}

export default App;
