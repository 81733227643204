import { generalInfos, siteData } from "./config";
import { emailTypes, pageTitleDictionary } from "./constants";

const detectMobile = () => {
  return window && window.innerWidth <= 800 && window.innerHeight <= 600;
};

const copyright = (lang: string) => {
  const fixParams = {
    producerName: "CloudDev Fusion",
    producerLink: "https://www.linkedin.com/company/98555794",
  };
  if (lang === "fr")
    return {
      ...fixParams,
      mention: ". Tous les droits sont réservés.",
      designedby: "Conçu par",
    };
  return {
    ...fixParams,
    mention: ". All Rights Reserved.",
    designedby: "Designed by",
  };
};

const formMapper = (lang: string) => [
  {
    id: "name",
    order: 1,
    type: "text",
    required: true,
    placeholder: lang === "fr" ? "Nom et prénom" : "Full name",
    minlength: 2,
  },
  {
    id: "email",
    order: 2,
    type: "email",
    required: true,
    placeholder: lang === "fr" ? "Adresse Email" : "Email adresse",
  },

  {
    id: "message",
    order: 7,
    type: "textarea",
    required: false,
    placeholder: lang === "fr" ? "Message" : "Message",
    minlength: 20,
  },
];
const contactFormMapper = (lang: string) => [
  ...formMapper(lang),
  {
    id: "subject",
    type: "text",
    order: 3,
    required: false,
    placeholder: lang === "fr" ? "Sujet" : "Subject",
    fullWidth: true,
    minlength: 5,
  },
];

const candidatureFormMapper = (lang: string) => [
  ...formMapper(lang),

  {
    id: "city",
    order: 4,
    type: "text",
    required: false,
    placeholder: lang === "fr" ? "Ville" : "City",
    minlength: 2,
  },
  {
    id: "phone",
    order: 5,
    type: "text",
    required: false,
    placeholder: lang === "fr" ? "Téléphone" : "Phone number",
    minlength: 9,
  },
  {
    id: "file",
    order: 6,
    type: "file",
    required: false,
    placeholder: lang === "fr" ? "Uploader le CV" : "Upload resume",
    fullWidth: true,
  },
];

const sweetMessages = (lang: string) => ({
  success:
    lang === "fr"
      ? "L'email a bien été envoyé."
      : "The email has been sent successfully.",
  error:
    lang === "fr"
      ? "L'email n'a pas bien envoyé, merci d'essayer à nouveau."
      : "Email failed to send, please try again.",
});
const candidature = (lang: string) => {
  return {
    header: lang === "fr" ? "Apply Spontaneously" : "Candidature spontanée",
    title:
      lang === "fr"
        ? "Apply to our upcoming offers"
        : "Postulez à nos prochaines offres",
    form: {
      fields: [...candidatureFormMapper(lang)],
      buttonTitle: lang === "fr" ? "Send Message" : "Envoyer le message",
      errorMessage:
        lang === "fr"
          ? "Please fill all the required fields correctly"
          : "Veuillez remplir correctement tous les champs obligatoires",
      type: emailTypes.apply,
      sweetMessages: sweetMessages(lang),
    },
  };
};

const contact = (lang: string) => ({
  header: lang === "fr" ? "Contactez-nous" : "Contact us",
  title:
    lang === "fr"
      ? "N'hésitez pas à nous contacter"
      : "Please Feel Free To Contact Us",
  form: {
    fields: [...contactFormMapper(lang)],
    buttonTitle: lang === "fr" ? "Envoyer" : "Send",
    errorMessage:
      lang === "fr"
        ? "Veuillez remplir correctement tous les champs obligatoires"
        : "Please fill all the required fields correctly",
    sweetMessages: sweetMessages(lang),
    type: emailTypes.contact,
  },
  contactInfos: [
    {
      title: lang === "fr" ? "Notre bureau" : "Our Office",
      value: generalInfos.companyAddress,
      type: "geo-alt",
    },
    {
      title: lang === "fr" ? "Envoyez-nous un email" : "Email Us",
      value: generalInfos.emailAddress,
      type: "envelope-open",
    },
    {
      title: lang === "fr" ? "Appelez-nous" : "Call Us",
      value: generalInfos.phoneNumber,
      type: "phone-vibrate",
    },
  ],
  // Please make sure u replace all special characters on the address with space.
  address: generalInfos.companyAddress,
});

const contactInfo = (lang: string) => ({
  title: lang === "fr" ? "Prendre contact" : "Get In Touch",
  ...generalInfos,
});

const headerData = (lang: string) =>
  siteData.header?.navLinks.map((item) => ({
    ...item,
    title:
      (pageTitleDictionary as any)[lang][item.url].shortTitle ??
      (pageTitleDictionary as any)[lang][item.url].title,
  }));
const footerPagelinks = (lang: string) => [
  {
    title: lang === "fr" ? "Liens rapides" : "Quick Links",
    linksTitles: headerData(lang)
      ?.filter((item) => item.show)
      .map((item) => ({ linkTitle: item.title, linkRef: `/${item.url}` })),
  },
];

const footerSocialMediaTitle = (lang: string) =>
  lang === "fr" ? "Suivez-nous" : "Follow us";

const heroGeneralData = (lang: string, page: string) => ({
  title: (pageTitleDictionary as any)[lang][page].title,
  child: {
    text: (pageTitleDictionary as any)[lang][page].title,
    url: page,
  },
  parent: {
    text: (pageTitleDictionary as any)[lang]["home"].title,
    url: "home",
  },
  bgImage: (pageTitleDictionary as any)[lang][page].bgImage,
});

const socialMediaLinks = () =>
  siteData.socialMediaLinks.filter((item) => item.link);
export {
  detectMobile,
  copyright,
  candidature,
  contact,
  contactInfo,
  footerPagelinks,
  footerSocialMediaTitle,
  heroGeneralData,
  headerData,
  socialMediaLinks,
};
