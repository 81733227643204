import HeroHeader from "../../components/HeroHeader";
import { generalInfos } from "../../utils/config";
import Candidature from "../../containers/Candidature";
import Career from "../../containers/Career";
import { candidature, heroGeneralData } from "../../utils/utils";

const index = (props: any) => {
  return (
    <>
      <HeroHeader
        {...props?.containers?.hero}
        {...heroGeneralData(generalInfos.lang, "career")}
      />
      <Career {...props?.containers?.career} />
      <Candidature {...candidature(generalInfos.lang)} />
    </>
  );
};

export default index;
