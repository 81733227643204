import HeroHeader from "../../components/HeroHeader";
import { generalInfos } from "../../utils/config";
import About from "../../containers/About";
import OldProjects from "../../containers/OldProjects";
import { heroGeneralData } from "../../utils/utils";

const index = (props: any) => {
  return (
    <>
      <HeroHeader
        {...props?.containers?.hero}
        {...heroGeneralData(generalInfos.lang, "about")}
      />
      <About {...props?.containers?.about} />
      <OldProjects {...props?.containers?.oldProjects} />
    </>
  );
};

export default index;
