import { aboutProps } from "../../utils/types";
import Rate from "../../assets/img/rate.png";
const index = (props: aboutProps) => {
  const { title, titleOffer, description, yearsOfExp, certificates } = props;
  if (!props) return <></>;
  let imageSrc;
  try {
    imageSrc = require(`../../config/images/about-container.jpg`);
  } catch (e) {
    imageSrc = "";
  }
  return (
    <div
      className="container-fluid py-5 wow fadeInUp"
      data-wow-delay="0.1s"
      style={{
        visibility: "visible",
        animationDelay: "0.1s",
        animationName: "fadeInUp",
      }}
    >
      <div className="container">
        <div className="row gx-5">
          <div
            className="col-lg-5 mb-5 mb-lg-0"
            style={{ minHeight: " 500px" }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.3s"
                src={imageSrc}
                style={{
                  objectFit: "cover",
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "zoomIn",
                }}
                alt="Image"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="mb-4">
              <h5
                className="text-primary text-uppercase"
                style={{
                  letterSpacing: "5px",
                }}
              >
                {title}
              </h5>
              <h1 className="display-5 mb-0">{titleOffer}</h1>
            </div>

            <p
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            <div className="row g-3">
              {yearsOfExp && (
                <div
                  className="col-sm-4 wow zoomIn"
                  data-wow-delay="0.6s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "zoomIn",
                  }}
                >
                  <div
                    className="bg-primary d-flex flex-column justify-content-center text-center border-bottom border-5 border-secondary rounded p-3"
                    style={{
                      height: "200px",
                    }}
                  >
                    <i className="fa fa-globe-europe fa-4x text-white mb-4"></i>
                    <h5 className="text-white mb-0">{yearsOfExp}</h5>
                  </div>
                </div>
              )}
              <div
                className="col-sm-4 wow zoomIn"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "zoomIn",
                }}
              >
                <div
                  className="d-flex flex-column justify-content-center align-items-center text-center border-5 rounded p-3"
                  style={{
                    height: "200px",
                  }}
                >
                  <a
                    href="https://www.designrush.com/agency/profile/clouddev-fusion"
                    target="_blank"
                  >
                    <img src={Rate} height={225} width={180} />
                  </a>
                </div>
              </div>
              {certificates && (
                <div
                  className="col-sm-4 wow zoomIn"
                  data-wow-delay="0.9s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.9s",
                    animationName: "zoomIn",
                  }}
                >
                  <div
                    className="bg-secondary d-flex flex-column justify-content-center text-center border-bottom border-5 border-primary rounded p-3"
                    style={{
                      height: "200px",
                    }}
                  >
                    <i className="fa fa-award fa-4x text-white mb-4"></i>
                    <h5 className="text-white mb-0">{certificates}</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
