import HeroHeader from "../../components/HeroHeader";
import { generalInfos } from "../../utils/config";
import Services from "../../containers/Services";
import Testmonial from "../../containers/Testimonial";
import { heroGeneralData } from "../../utils/utils";
const index = (props: any) => {
  return (
    <>
      <HeroHeader
        {...props?.containers?.hero}
        {...heroGeneralData(generalInfos.lang, "services")}
      />
      <Services {...props.containers?.services} />
      <Testmonial {...props.containers?.testmonial} />
    </>
  );
};

export default index;
