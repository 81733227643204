import { Link } from "react-router-dom";
import { NavItemProps } from "../../utils/types";

const NavItem = ({ url, title, isActive, show, onClick }: NavItemProps) => {
  if (!show) return <></>;
  return (
    <Link
      to={{ pathname: url }}
      className={`nav-item nav-link ${isActive && "active"}`}
      onClick={() => {
        onClick(false);
        window.location.href = url;
      }}
    >
      {title}
    </Link>
  );
};

NavItem.defaultProps = {
  isActive: false,
};

export default NavItem;
