import { ContactProps } from "../../utils/types";
import Iframe from "react-iframe";
const index = ({ contactInfos, address }: ContactProps) => {
  return (
    <div className="bg-light rounded p-5">
      {contactInfos.map((item) => (
        <div className="d-flex align-items-center mb-2">
          <i className={`bi bi-${item.type} fs-1 text-primary me-3`}></i>
          <div className="text-start">
            <h5 className="mb-1">{item.title}</h5>
            <span>{item.value}</span>
          </div>
        </div>
      ))}

      <div>
        <Iframe
          url={
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.29624870329!2d2.301834276458321!3d48.87162879977483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f5506b2f865%3A0x111a7a66ccad9fd8!2sCloudDev%20Fusion!5e0!3m2!1sen!2sfr!4v1693132424384!5m2!1sen!2sfr"
          }
          width="640px"
          height="252px"
          id=""
          className="position-relative w-100"
          display="block"
          position="relative"
        />
      </div>
    </div>
  );
};

export default index;
