import navList from "../config/header/nav.json";
import socialMediaLinks from "../config/footer/socialMediaLinks.json";
import generalInfos from "../config/general/generalInfos.json";
import stylingConfig from "../config/general/stylingConfig.json";

import heroAbout from "../config/heros/about.json";
import heroCareer from "../config/heros/career.json";
import heroContact from "../config/heros/contact.json";
import heroServices from "../config/heros/services.json";

import aboutContainer from "../config/containers/about.json";
import careerContainer from "../config/containers/career.json";
import servicesContainer from "../config/containers/services.json";
import oldProjectsContainer from "../config/containers/oldprojects.json";
import testmoniamsContainer from "../config/containers/testmonial.json";
import carouselContainer from "../config/containers/carousel.json";
import { pageTitleDictionary } from "./constants";

const lang = generalInfos.lang;
const commonContainers = {
  services: servicesContainer,
  about: aboutContainer,
  testmonial: testmoniamsContainer,
};

const siteData = {
  header: {
    navLinks: navList,
  },
  socialMediaLinks: socialMediaLinks,

  pages: {
    home: {
      pageTitle: (pageTitleDictionary as any)[lang]["home"].title,
      containers: {
        carousel: carouselContainer,
        ...commonContainers,
      },
    },
    about: {
      pageTitle: (pageTitleDictionary as any)[lang]["about"].title,
      containers: {
        hero: heroAbout,
        about: { ...commonContainers.about },
        oldProjects: oldProjectsContainer,
      },
    },
    services: {
      pageTitle: (pageTitleDictionary as any)[lang]["services"].title,
      containers: {
        hero: heroServices,
        services: { ...commonContainers.services },
        testmonial: { ...commonContainers.testmonial },
      },
    },
    contact: {
      pageTitle: (pageTitleDictionary as any)[lang]["contact"].title,
      containers: {
        hero: heroContact,
      },
    },
    career: {
      pageTitle: (pageTitleDictionary as any)[lang]["career"].title,
      containers: {
        hero: heroCareer,
        career: careerContainer,
      },
    },
  },
};

export { siteData, generalInfos, stylingConfig, socialMediaLinks };
