import HeroHeader from "../../components/HeroHeader";
import { generalInfos } from "../../utils/config";
import ContactUs from "../../containers/ContactUs";
import { contact, heroGeneralData } from "../../utils/utils";

const index = (props: any) => {
  return (
    <>
      <HeroHeader
        {...props?.containers?.hero}
        {...heroGeneralData(generalInfos.lang, "contact")}
      />
      <ContactUs {...contact(generalInfos.lang)} />
    </>
  );
};

export default index;
