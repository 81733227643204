const emailTypes = {
  apply: "apply",
  contact: "contact",
};
const pageTitleDictionary = {
  fr: {
    home: { title: "Accueil" },
    about: {
      title: "À propos de nous",
      bgImage: "aboutus-bg.jpg",
      shortTitle: "À propos",
    },
    services: { title: "Services", bgImage: "services-bg.jpg" },
    career: { title: "Carrière", bgImage: "career-bg.jpg" },
    contact: { title: "Contactez-nous", bgImage: "contact-bg.jpg" },
  },
  en: {
    home: { title: "Home" },
    about: { title: "About us", bgImage: "aboutus-bg.jpg" },
    services: { title: "Services", bgImage: "services-bg.jpg" },
    career: { title: "Career", bgImage: "career-bg.jpg" },
    contact: { title: "Contact us", bgImage: "contact-bg.jpg" },
  },
};
export { emailTypes, pageTitleDictionary };
