import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Helmet } from "react-helmet";

//  Libraries Stylesheet
import "./assets/lib/flaticon/font/flaticon.css";
import "./assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "./assets/lib/animate/animate.min.css";
import "./globalStyles.css";

// Customized Bootstrap Stylesheet
import "./assets/css/bootstrap.min.css";
//  Libraries Stylesheet
import "./assets/css/style.css";
import { stylingConfig } from "./utils/config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
let faviconUrl;
try {
  faviconUrl = require("./config/images/favicon.ico");
} catch (e) {
  faviconUrl = "";
}

root.render(
  <React.StrictMode>
    <App />
    <style
      dangerouslySetInnerHTML={{
        __html: `:root {
          --primary: ${
            !stylingConfig.primaryColor ? "#2878eb" : stylingConfig.primaryColor
          };
          --secondary: ${
            !stylingConfig.secondaryColor
              ? "#f14d5d"
              : stylingConfig.secondaryColor
          };
          --dark: ${
            !stylingConfig.darkColor ? "#120f2d" : stylingConfig.darkColor
          };
          --light: #ecf4ff;
        }`,
      }}
    ></style>
    <Helmet>
      <link href={faviconUrl} rel="icon" />
      <script src="https://code.jquery.com/jquery-3.4.1.min.js" defer></script>
      <link href={faviconUrl} rel="icon" />
      <script
        src="./assets/lib/wow/wow.min.js"
        type="text/javascript"
        defer
      ></script>

      <script
        src="./assets/lib/easing/easing.min.js"
        type="text/javascript"
        defer
      ></script>

      <script
        src="./assets/lib/waypoints/waypoints.min.js"
        type="text/javascript"
        defer
      ></script>

      <script
        src="./assets/lib/owlcarousel/owl.carousel.min.js"
        type="text/javascript"
        defer
      ></script>

      <script src="./assets/lib/main.js" type="text/javascript" defer></script>
    </Helmet>
  </React.StrictMode>
);
