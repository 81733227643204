import { generalInfos } from "../../utils/config";
import { SocialMediaLinksProps } from "../../utils/types";
import { footerSocialMediaTitle } from "../../utils/utils";
const index = ({ SocialMediaLinks }: SocialMediaLinksProps) => {
  return (
    <div className="col-lg-3 col-md-6">
      <h3 className="text-white mb-4">
        {footerSocialMediaTitle(generalInfos.lang)}
      </h3>
      <div className="d-flex">
        {SocialMediaLinks.map((item, index) => (
          <a
            href={item.link}
            className={`btn btn-lg btn-primary btn-lg-square rounded me-2`}
          >
            <i className={`fab fa-${item.provider} fw-normal`}></i>
          </a>
        ))}
      </div>
    </div>
  );
};
index.defaultProps = {
  isActive: false,
};

export default index;
